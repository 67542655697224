import { writable } from 'svelte/store';
import { getStoredContact, setStoredContact } from '../utils/storedContact';

const storedContact = getStoredContact();

const financingState = writable({
  // state
  disabledComponent: false,
  error: null,
  businessUnit: null,
  company: null,
  loading: true,
  step: 0,

  // contact
  customFields: [],
  name: storedContact.name,
  lastName: storedContact.lastName,
  phone: storedContact.phone,
  email: storedContact.email,
  extraFields: storedContact.contactExtraFields,
  contactFormHasErrors: true,

  // financing
  vehiclePrice: 0,
  /**
   * @type {{ terms: number payment: number }}
   */
  terms: null,
  downPayment: 0,
});

financingState.subscribe((state) => {
  setStoredContact({
    name: state.name,
    lastName: state.lastName,
    phone: state.phone,
    email: state.email,
    contactExtraFields: state.extraFields,
  });
});

export default financingState;
