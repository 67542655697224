<script>
  import { Select, TextInput } from 'wind-svelte';
  import currencyFormatter from '../utils/currencyFormatter';
  import percentageFormatter from '../utils/percentageFormatter';

  export let field = null;
  export let value = null;
  export let error = null;

  // this is reactive, if field.required changes, mainRule will change too
  $: mainRule = (value) => !field.required || (value !== null && !!value.trim()) || 'Campo requerido'

  $: label = `${field.name}${field.required ? '' : ' (Opcional)'}`;
</script>

{#if field.type === 'text'}
  <TextInput
    {label}
    filled
    fullWidth
    maxlength={field.max || 30}
    rules={[
      mainRule,
    ]}
    bind:value={value}
    bind:error={error}
  />
{:else if field.type === 'number'}
  <TextInput
    {label}
    filled
    fullWidth
    type="number"
    rules={[
      mainRule,
    ]}
    bind:value={value}
    bind:error={error}
  />
{:else if field.type === 'currency'}
  <!-- type="phone" to show numeric input for mobile -->
  <TextInput
    {label}
    filled
    fullWidth
    type="phone"
    formatter={currencyFormatter}
    rules={[
      mainRule,
    ]}
    bind:value={value}
    bind:error={error}
  />
{:else if field.type === 'percentage'}
  <TextInput
    {label}
    filled
    fullWidth
    type="phone"
    formatter={percentageFormatter}
    rules={[
      mainRule,
    ]}
    bind:value={value}
    bind:error={error}
  />
{:else if field.type === 'date'}
  <TextInput
    {label}
    filled
    fullWidth
    type="date"
    rules={[
      mainRule,
    ]}
    bind:value={value}
    bind:error={error}
  />
{:else if field.type === 'datetime'}
  <TextInput
    {label}
    fullWidth
    type="datetime-local"
    rules={[
      mainRule,
    ]}
    bind:value={value}
    bind:error={error}
  />
{:else if field.type === 'select'}
  <Select
    {label}
    filled
    fullWidth
    clearable
    options={field.options}
    rules={[
      mainRule,
    ]}
    bind:value={value}
    bind:error={error}
  />
{/if}
