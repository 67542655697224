<script>
  export let title = "";
  let isHovered = false;
  let x;
  let y;

  function onMouseOver(event) {
    isHovered = true;
    x = event.clientX + 16;
    y = event.clientY - 8;
  }

  function onMouseMove(event) {
    x = event.clientX + 16;
    y = event.clientY - 8;
  }

  function onMouseLeave() {
    isHovered = false;
  }
</script>

<div
  on:focus={() => {}}
  on:mouseover={onMouseOver}
  on:mouseleave={onMouseLeave}
  on:mousemove={onMouseMove}
>
  <slot />
</div>

{#if isHovered && title}
  <div
    style="top: {y}px; left: {x}px;"
    class="fixed"
    text="size-sm white"
    py="2"
    px="4"
    rounded="md"
    opacity="90"
    z="10"
    max-w="80"
    bg="gray-800"
  >
    {title}
  </div>
{/if}
