<script>
  import { createEventDispatcher } from 'svelte';
  import { Alert } from 'wind-svelte';

  const dispatch = createEventDispatcher();

  export let error;
  export let closable;
</script>

{#if error}
  <Alert
    {closable}
    title="Error"
    on:close={() => dispatch('close')}
  >
    {#if typeof error === 'string'}
      {error}
    {:else if error.message}
      {error.message}
      {#if error.details instanceof Array}
        <ul>
          {#each error.details as detail}
            <li>{detail}</li>
          {/each}
        </ul>
      {/if}
    {/if}
  </Alert>
{/if}
