<script>
  import AmountSlider from '../components/AmountSlider.svelte';
  import toCurrency from '../utils/toCurrency';
  import financingConfig from './financingConfig';
  import financingState from './financingState';

  let selection = 0;
  $: if (!selection) {
    selection = $financingConfig.minVehiclePrice;
  }
</script>

{#if !$financingConfig.vehiclePrice}
  <AmountSlider
    min={$financingConfig.minVehiclePrice}
    max={$financingConfig.maxVehiclePrice}
    step={10000}
    label="Valor del auto"
    bind:value={$financingState.vehiclePrice}
    bind:selection={selection}
  />
{/if}
