<script>
  import ErrorAlert from '../components/ErrorAlert.svelte';
  import financingState from './financingState';
</script>

<ErrorAlert
  error={$financingState.error}
  closable={!$financingState.disabledComponent}
  on:close={() => $financingState.error = null}
/>
