<script>
  import ContactFields from '../components/ContactFields.svelte';
  import financingConfig from './financingConfig';
  import financingState from './financingState';
</script>

<ContactFields
  smCols={1}
  mdCols={$financingConfig.contactMdCols}
  lgCols={$financingConfig.contactLgCols}
  xlCols={$financingConfig.contactXlCols}
  xxlCols={$financingConfig.contactXxlCols}
  hasLastName={$financingConfig.lastName}

  bind:hasError={$financingState.contactFormHasErrors}

  bind:name={$financingState.name}
  bind:lastName={$financingState.lastName}
  bind:phone={$financingState.phone}
  bind:email={$financingState.email}
  bind:extraFields={$financingState.extraFields}
/>
