<script>
  import { Slider } from 'wind-svelte';
  import toCurrency from '../utils/toCurrency';

  export let min;
  export let max;
  export let value;
  export let selection;
  export let step;
  export let label;
</script>

<div>
  <div class="text-center">
    <b class="text-lg">
      {toCurrency(selection)}
    </b>
    <div class="text-sm">
      {label}
    </div>
  </div>
  <div class="-mx-2">
    <Slider
      {min}
      {max}
      {step}
      fullWidth
      bind:value={value}
      bind:selection={selection}
    />
  </div>
  <div class="flex justify-between text-sm">
    <span>
      {toCurrency(min)}
    </span>
    <span>
      {toCurrency(max)}
    </span>
  </div>
</div>
