<script>
  import { OptionsHandler, Button, Grid } from 'wind-svelte';
  import calculateMonthlyPayment from '../utils/calculateMonthlyPayment';
  import toCurrency from '../utils/toCurrency';
  import financingConfig from './financingConfig';
  import financingState from './financingState';

  const identify = (option) => option?.terms;

  $: options = $financingConfig.terms.map((terms) => ({
    terms,
    payment: calculateMonthlyPayment({
      price: $financingState.vehiclePrice,
      months: terms,
      downPayment: $financingState.downPayment,
      interestRate: $financingConfig.interestRate / 12,
    }),
  }));
</script>

<div>
  <div class="text-center mb-2">
    {$financingConfig.financingTermsCopy}
  </div>
  <Grid
    spacing={0.25}
    cols={$financingConfig.termsCols}
    smCols={$financingConfig.termsSmCols}
    mdCols={$financingConfig.termsMdCols}
    lgCols={$financingConfig.termsLgCols}
    xlCols={$financingConfig.termsXlCols}
    xxlCols={$financingConfig.termsXxlCols}
  >
    <OptionsHandler {options} {identify} bind:value={$financingState.terms} >
      <svelte:fragment let:option let:selected let:toggle>
        <Button
          fullWidth
          color={selected ? 'primary' : null}
          on:click={toggle}
        >
          <div class="flex flex-col">
            <b class="text-lg">
              {toCurrency(option.payment)}
            </b>
            <span class="text-xs font-light">
              {option.terms} meses
            </span>
          </div>
        </Button>
      </svelte:fragment>
    </OptionsHandler>
  </Grid>
</div>
