<script>
  import { Markdown, Button } from 'wind-svelte';
  import financingState from './financingState';
  import financingConfig from './financingConfig';
  import fetchFinancingInterest from '../utils/fetchFinancingInterest';
  import FinancingContactForm from './FinancingContactForm.svelte';

  let loading = false;

  async function next() {
    try {
      $financingState.error = null;
      loading = true;

      await fetchFinancingInterest({
        agency: $financingConfig.businessUnit,
        channel: $financingConfig.channel,
        vehicle: $financingConfig.vehicle,
        vehiclePrice: $financingState.vehiclePrice,
        downPayment: $financingState.downPayment,
        terms: $financingState.terms.terms,
        termsPayment: $financingState.terms.payment,
        name: $financingState.name,
        lastName: $financingConfig.lastName ? $financingState.lastName : null,
        email: $financingState.email,
        phone: $financingState.phone,
        extraFields: $financingState.extraFields || [],
      });

      $financingState.step++;
    } catch (error) {
      // show error message
      $financingState.error = 'Ocurrió un problema al realizar la solicitud, intente de nuevo mas tarde';
      console.error(error);
    } finally {
      loading = false;
    }
  }
</script>

<div class="text-sm text-gray-500">
  <Markdown template={$financingConfig.contactDescription} />
</div>

<FinancingContactForm />

<div class="flex justify-end">
  <Button
    text
    color="primary"
    disabled={loading}
    on:click={() => $financingState.step--}
  >
    Anterior
  </Button>
  <Button
    {loading}
    color="primary"
    disabled={$financingState.contactFormHasErrors}
    on:click={next}
  >
    Siguiente
  </Button>
</div>
