<script>
  import createChecker from '../utils/createChecker';
  import configStore from './configStore';
  import valuationStore from './valuationStore';

  export let ready = false;

  let mapElement = null;
  let map = null;
  let markers = null;

  function onLoad() {
    ready = true;
  }

  // - - - Reactivity

  // Setup map
  $: if (ready && mapElement) {
    // console.log('setup map');
    map = new google.maps.Map(mapElement, {
      zoom: 8,
    });
  }

  // Create markers
  const createMarkersChecker = createChecker(() => {
    if (!map || !$valuationStore.agenciesList) return;

    // console.log('create markers');
    if (markers) {
      markers.forEach((marker) => marker.setMap(null));
    }

    markers = $valuationStore.agenciesList.map((agency, index) => {
      const marker = new google.maps.Marker({
        map,
        label: agency.mapLabel,
        position: {
          lat: Number(agency.address.lat),
          lng: Number(agency.address.lon),
        },
      });

      marker.addListener('click', () => {
        $valuationStore.agency = agency;
      });
      return marker;
    });
  });
  $: createMarkersChecker(map, $valuationStore.agenciesList);

  // Center on select agency
  $: if (map && $valuationStore.agency) {
    // console.log('Center selected agency');
    map.panTo({
      lat: Number($valuationStore.agency.address.lat),
      lng: Number($valuationStore.agency.address.lon),
    });
    map.setZoom(14);
  }

  // Show all marks if no agency selected
  $: if (map && !$valuationStore.agency && markers) {
    // console.log('Show all marks if no agency selected');
    const bounds = new google.maps.LatLngBounds();
    markers.map((marker) => bounds.extend(marker.getPosition()));
    map.fitBounds(bounds);
  }
</script>

<svelte:head>
  <script
    async
    src="https://maps.googleapis.com/maps/api/js?key={$configStore.googleMapsKey}&v=weekly"
    on:load={onLoad}
  />
</svelte:head>

{#if ready}
  <div class="h-96 mb-2" bind:this={mapElement} />
{/if}
