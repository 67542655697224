const PATTERN = /[^\d]/g;

function toPercent(value) {
  return value ? `${value}%` : '';
}

function toValue(text) {
  const parsedText = text.replace(PATTERN, '');
  const value = Number(parsedText);

  if (value > 100) return 100;
  if (value < 0) return 0;

  return value;
}

function toText(value, event) {
  if (!event) return toPercent(value);

  const { data, target } = event;
  if (event.inputType === 'deleteContentBackward' && target.value) {
    const newValue = target.value.slice(0, -1);
    return toPercent(newValue);
  }

  // revert event if data is not numeric character
  if (data && PATTERN.test(data)) {
    return target.value.replace(data, '');
  }

  const newValue = toValue(target.value, event);
  return toPercent(newValue);
}


const percentageFormatter = { toText, toValue };

export default percentageFormatter;
