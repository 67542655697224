
<script>
  import { getContext } from 'svelte';
  import { Card } from 'wind-svelte';
  import { fitsh } from 'fitsh';
  import createChecker from '../utils/createChecker';
  import financingConfig from './financingConfig';
  import financingState from './financingState';
  import FinancingError from './FinancingError.svelte';
  import FinancingWizard from './FinancingWizard.svelte';

  const dispatchEvent = getContext('dispatchEvent');

  let available = false;
 
  const fetchAgencyChecker = createChecker(async () => {
    if ($financingConfig.stop) return;

    $financingState.disabledComponent = false;
    $financingState.error = null;
    $financingState.company = null;

    if (!$financingConfig.businessUnit) {
      $financingState.disabledComponent = true;
      $financingState.error = 'Se necesita configurar una agencia (atributo "business-unit")';
      return;
    }
    if (!$financingConfig.channel) {
      $financingState.disabledComponent = true;
      $financingState.error = 'Se debe configurar un canal (atributo "channel")';
      return;
    }

    try {
      $financingState.loading = true;

      const company = await fitsh(`${API_URL}/financing/company/${$financingConfig.businessUnit}`).get({
        channel: $financingConfig.channel,
      });
      $financingState.company = company;
    } catch (error) {
      $financingState.disabledComponent = true;
      $financingState.error = error?.content?.error || 'Ocurrio un problema al obtener la agencia';
      return;
    } finally {
      $financingState.loading = false;
    }

    if (!$financingState.company.available) {
      console.error('Sin subscripción al servicio de financiamiento');
    } else {
      available = true;
    }

    dispatchEvent('company-fetched', $financingState.company);
  });

  $: fetchAgencyChecker($financingConfig.businessUnit, $financingConfig.channel, $financingConfig.stop);
</script>

{#if available}
  <div class="font-sans text-base w-full {$financingConfig.fullWidth ? '' : 'sm:max-w-96'}">
    {#if $financingConfig.noCard}
      {#if $financingState.disabledComponent}
        <FinancingError />
      {:else}
        <h2 class="text-center text-xl">Financia tu auto</h2>
        <FinancingWizard />
      {/if}
    {:else}
      <Card fullWidth={$financingConfig.fullWidth}>
        {#if $financingState.disabledComponent}
          <FinancingError />
        {:else}
          <h2 class="text-center text-xl">Financia tu auto</h2>
          <FinancingWizard />
        {/if}
      </Card>
    {/if}
  </div>
{/if}
