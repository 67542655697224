import { fitsh } from 'fitsh';

export default async function fetchValuation({
  authorization,
  // vehicle
  brand,
  model,
  year,
  trim,
  kms,
  vin,
  licensePlate,
  vehicleExtraFields,
  // contact
  name,
  lastName,
  phone,
  email,
  contactExtraFields,
  // agency
  agency,
}) {
  const url = new URL(location);
  const utmCampaign = url.searchParams.get('utm_campaign') || null;
  const valuation = await fitsh(`${API_URL}/publicValuations`).post({
    authorization,
    utmCampaign,
    // vehicle
    brandId: brand.id,
    modelId: model.id,
    yearId: year.id,
    trimId: trim.id,
    kms,
    vin,
    licensePlate,
    vehicleExtraFields,
    // contact
    name,
    lastName,
    phone,
    email,
    contactExtraFields,
    // agency
    businessUnitId: agency.id,
    channelName: agency.channel,
  });

  return valuation;
}
