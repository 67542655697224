<script>
  import { Button } from 'wind-svelte';
  import valuationStore from './valuationStore';
  import configStore from './configStore';
  import NextStepButton from './NextStepButton.svelte';
  import ValuationError from './ValuationError.svelte';
  import ContactFields from '../components/ContactFields.svelte';

  // state
  let hasErrors = false;
</script>

<div class="font-sans text-sm antialiased text-gray-500 leading-normal mb-4">
  {$configStore.contactText}
</div>

<ContactFields
  smCols={1}
  mdCols={$configStore.mdCols}
  lgCols={$configStore.lgCols}
  xlCols={$configStore.xlCols}
  xxlCols={$configStore.xxlCols}
  hasLastName={$configStore.lastName}

  bind:hasError={hasErrors}

  bind:name={$valuationStore.name}
  bind:lastName={$valuationStore.lastName}
  bind:phone={$valuationStore.phone}
  bind:email={$valuationStore.email}
  bind:extraFields={$valuationStore.contactExtraFields}
/>

<div class="mt-4">
  <ValuationError />
  <div class="flex justify-end">
    <Button
      text
      color="primary"
      on:click={() => $valuationStore.step--}
    >
      Anterior
    </Button>
    <NextStepButton disabled={hasErrors} />
    {#if IS_DEV}
      <Button on:click={() => $valuationStore.step++}>SALTAR</Button>
    {/if}
  </div>
</div>
