<script>
  import { Button, Grid, Markdown, getColor } from 'wind-svelte';
  import valuationStore from './valuationStore';
  import configStore from './configStore';
  import BrandField from './BrandField.svelte';
  import ModelField from './ModelField.svelte';
  import YearField from './YearField.svelte';
  import TrimField from './TrimField.svelte';
  import ValuationError from './ValuationError.svelte';
  import LicencePlateField from './LicencePlateField.svelte';
  import KmsField from './KmsField.svelte';
  import VinField from './VinField.svelte';
  import CustomField from './CustomField.svelte';
  import NextButton from './NextStepButton.svelte';
  import Checkbox from '../components/Checkbox.svelte';
  import sendCustomEvent from '../utils/sendCustomEvent';

  const primaryColor = getColor('primary');

  $: kmsInput = $configStore.vehicleFields.find((field) => field.name === 'kms');
  $: vinInput = $configStore.vehicleFields.find((field) => field.name === 'vin');
  $: plateInput = $configStore.vehicleFields.find((field) => field.name === 'license-plate');

  $: optionalKms = !kmsInput || kmsInput.optional;
  $: optionalVin = !vinInput || vinInput.optional;
  $: optionalPlate = !plateInput || plateInput.optional;

  $: brandValid = !!$valuationStore.brand;
  $: modelValid = !!$valuationStore.model;
  $: trimValid = !!$valuationStore.trim;
  $: yearValid = !!$valuationStore.year;
  $: kmsValid = optionalKms || !!$valuationStore.kms;
  $: vinValid = optionalVin || ($valuationStore.vin || '').length === 17;
  $: plateValid = optionalPlate || !!$valuationStore.licensePlate;
  
  $: customFieldsValid = $valuationStore.vehicleExtraFields.every((field) => {
    return field.optional || field.value || field.value === 0;
  });

  $: confirmationValid = !$configStore.confirmVehicleInfo || $valuationStore.confirmedVehicleInfo;

  $: completed = brandValid
    && modelValid
    && trimValid
    && yearValid
    && kmsValid
    && vinValid
    && plateValid
    && customFieldsValid
    && confirmationValid
  ;

  $: col = $configStore.prominent ? 2 : 1;

  $: brandValid && sendCustomEvent('set-vehicle-brand');
  $: modelValid && sendCustomEvent('set-vehicle-model');
  $: yearValid && sendCustomEvent('set-vehicle-year');
  $: trimValid && sendCustomEvent('set-vehicle-trim');
  $: kmsValid && sendCustomEvent('set-vehicle-kms');
  $: confirmationValid && sendCustomEvent('set-vehicle-confirmation');
</script>

<div class="font-sans text-sm antialiased text-gray-500 leading-normal mb-4">
  <Markdown template={$configStore.vehicleText} />
</div>

<Grid
  spacing={0.25}
  smCols={1}
  mdCols={$configStore.mdCols}
  lgCols={$configStore.lgCols}
  xlCols={$configStore.xlCols}
  xxlCols={$configStore.xxlCols}
>
  <div col={col}>
    <BrandField />
  </div>
  
  <div col={col}>
    <ModelField />
  </div>

  <div col={col}>
    <YearField />
  </div>
  
  <div col={col}>
    <TrimField />
  </div>

  {#if kmsInput}
    <div col={col}>
      <KmsField />
    </div>
  {/if}

  {#if vinInput}
    <div col={col}>
      <VinField required={!vinInput.optional} />
    </div>
  {/if}

  {#if plateInput}
    <div col={col}>
      <LicencePlateField />
    </div>
  {/if}

  {#each $valuationStore.vehicleExtraFields as field}
    <div col={col}>
      <CustomField
        field={field}
        bind:value={field.value}
      />
    </div>
  {/each}
</Grid>

{#if $configStore.confirmVehicleInfo}
  <div class="mt-4">
    <Checkbox
      label={$configStore.confirmVehicleInfoText}
      color={$primaryColor.hex}
      bind:checked={$valuationStore.confirmedVehicleInfo}
    />
  </div>
{/if}

<div class="mt-4">
  <ValuationError />
  <div class="flex justify-end">
    <NextButton disabled={!completed} />
    {#if IS_DEV}
      <Button on:click={() => $valuationStore.step++}>
        SALTAR
      </Button>
    {/if}
  </div>
</div>
