import { writable } from 'svelte/store';

const configStore = writable({
  vehicleTitle: null,
  vehicleText: null,
  contactTitle: null,
  contactText: null,
  agencyTitle: null,
  agencyText: null,
  valuationTitle: null,
  valuationTopText: null,
  valuationBottomText: null,
  valuationDisclaimer: null,
  noValuationText: null,
  webComponentContainer: null,
  vehicleFields: null,
  vehicleExtraFields: null,
  fullWidth: false,
  googleMapsKey: null,
  legalText: null,
  mdCols: 0,
  lgCols: 0,
  xlCols: 0,
  xxlCols: 0,
  minYearFilter: 0,
  noResultsCopy: '',
  agencyDistanceRequired: false,
  lastName: false,
  groupLogo: null,
  phoneVerificationTitle: null,
  phoneVerificationText: null,
  confirmVehicleInfo: false,
  confirmVehicleInfoText: null,
  tooltips: null,
});

export default configStore;
