import { fitsh } from "fitsh";

export default async function fetchFinancingInterest({
  agency,
  channel,
  vehiclePrice,
  downPayment,
  terms,
  termsPayment,
  name,
  lastName,
  email,
  phone,
  extraFields,
  vehicle,
}) {
  const url = new URL(location);
  const utmCampaign = url.searchParams.get('utm_campaign') || null;

  const response = await fitsh(`${API_URL}/financing/interest`).post({
    agency,
    channel,
    utmCampaign,
    vehiclePrice,
    downPayment,
    terms,
    termsPayment,
    name,
    lastName,
    email,
    phone,
    extraFields,
    vehicle,
  });

  return response;
}
