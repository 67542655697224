<script>
  import { TextInput } from 'wind-svelte';
  import valuationStore from './valuationStore';
  import configStore from './configStore';

  $: input = $configStore.vehicleFields.find((field) => field.name === 'license-plate');
  $: label = input ? `Placa${input.optional ? ' (opcional)' : ''}` : '';
</script>

<TextInput
  {label}
  fullWidth
  filled
  bind:value={$valuationStore.licensePlate}
/>
