<script>
  import { Markdown, Button } from 'wind-svelte';
  import financingState from './financingState';
  import DownPaymentField from './DownPaymentField.svelte';
  import VehiclePriceField from './VehiclePriceField.svelte';
  import TermsField from './TermsField.svelte';
  import financingConfig from './financingConfig';
</script>

<div class="text-sm text-gray-500">
  <Markdown template={$financingConfig.financingDescription} />
</div>

<VehiclePriceField />
<DownPaymentField />
<TermsField  />

<div class="text-sm text-gray-500">
  <Markdown
    template={$financingConfig.financingDisclaimer}
    variables={{ interestRate: $financingConfig.interestRate }}
  />
</div>

<div class="flex justify-end">
  <Button
    color="primary"
    disabled={!$financingState.terms}
    on:click={() => $financingState.step++}
  >
    Siguiente
  </Button>
</div>
