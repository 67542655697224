<script>
  import { getContext } from 'svelte';
  import financingState from './financingState';
  import financingConfig from './financingConfig';
  import WizardSteps from '../components/WizardSteps.svelte';
  import PoweredBy from '../components/PoweredBy.svelte';
  import CalculatorStep from './CalculatorStep.svelte';
  import FinancingContactStep from './FinancingContactStep.svelte';
  import FinancingResultStep from './FinancingResultStep.svelte';
  import FinancingError from './FinancingError.svelte';
  import createChecker from '../utils/createChecker';

  const dispatchEvent = getContext('dispatchEvent');
  const stepNames = ['financing', 'contact', 'finish'];

  const stepChangedChecker = createChecker((prevStep) => {
    if (prevStep >= $financingState.step) return;

    dispatchEvent('next-step', {
      ...$financingState,
      stepName: stepNames[$financingState.step],
    });
  }, $financingState.step);

  $: stepChangedChecker($financingState.step);
</script>

<WizardSteps
  steps={[
    $financingConfig.financingTitle,
    $financingConfig.contactTitle,
    $financingConfig.finishTitle,
  ]}
  value={$financingState.step}
/>

<div class="flex flex-col gap-4 my-4">
  {#if $financingState.step === 0}
    <CalculatorStep />
  {:else if $financingState.step === 1}
    <FinancingContactStep />
  {:else if $financingState.step === 2}
    <FinancingResultStep />
  {/if}
  <FinancingError />
</div>

<PoweredBy />
