<script>
  import { Card } from 'wind-svelte';
  import fetchAgencies from '../utils/fetchAgencies';
  import createChecker from '../utils/createChecker';
  import stateStore from './stateStore';
  import configStore from './configStore';
  import valuationStore from './valuationStore';
  import ValuationError from './ValuationError.svelte';
  import ValuationSteps from './ValuationSteps.svelte';
  import Recaptcha from './Recaptcha.svelte';
  import ValuationPoweredBy from './ValuationPoweredBy.svelte';
  import GroupLogo from './GroupLogo.svelte';

  // reactivity
  const fetchAgenciesChecker = createChecker(async () => {
    $stateStore.error = '';
    $stateStore.disableComponent = false;

    try {
      $valuationStore.agenciesList = await fetchAgencies({
        businessUnits: $valuationStore.requiredCompanies,
      });
      if ($valuationStore.agenciesList.length === 0) {
        $stateStore.error = 'Ocurrió un problema con la/las agencias, verifique la configuración del componente';
        $stateStore.disableComponent = true;
      }
    } catch (error) {
      console.error(error);
      $stateStore.disableComponent = true;
      $stateStore.error = error.content && error.content.error || 'Ocurrió un problema con la agencia, intente de nuevo mas tarde';
    }
  });
  $: fetchAgenciesChecker($valuationStore.requiredCompanies);

  $: if ($valuationStore.requiredCompanies.some(item => !item.channel)) {
    $stateStore.error = 'Todas las agencias deben tener un canal configurado';
    $stateStore.disableComponent = true;
  }

  $: showRecaptcha = !$valuationStore.authorization && ($valuationStore.brand || $valuationStore.hasValuated);
</script>

<div class="w-full {$configStore.fullWidth ? '' : 'sm:max-w-96'} font-sans text-base">
  <Card relative fullWidth={$configStore.fullWidth}>
    {#if $stateStore.disableComponent}
      <ValuationError />
    {:else}
      {#if showRecaptcha}
        <div>
          <Recaptcha bind:authorization={$valuationStore.authorization} />
        </div>
      {/if}
      <GroupLogo />
      <div>
        <ValuationSteps />
      </div>
      <ValuationPoweredBy />
    {/if}
  </Card>
</div>
