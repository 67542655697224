import { fitsh } from 'fitsh';

export default async function getchZipCodeLocation({ zipCode, authorization}) {
  const { lat, long: lon } = await fitsh(`${API_URL}/zipcode-centroid`).get({
    zipcode: zipCode,
    authorization,
  });

  return { lat, lon };
}
