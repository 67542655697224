// http://www.gyplan.com.br/amofran_en.html
// https://www.calculatestuff.com/financial/loan-amortization-calculator

/**
 * @typedef { Object } CalcOptions
 * @property { number } price
 * @property { number } months
 * @property { number } interestRate
 * @property { number } downPayment
 */

/**
 * 
 * @param { CalcOptions } param0 
 */
export default function calculateMonthlyPayment({ price, months, interestRate, downPayment = 0 }) {
  const financing = price - downPayment;
  const normalizedInterestRate = interestRate / 100;

  const payment =
    financing *
    normalizedInterestRate *
    Math.pow(1 + normalizedInterestRate, months) / (Math.pow(1 + normalizedInterestRate, months) - 1);
  return payment.toFixed(2);
}
