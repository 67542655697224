function hasDiff(prevValues, values) {
  if (prevValues.length !== values.length) return true;

  return prevValues.some((prevValue, index) => {
    return prevValue !== values[index];
  });
}

export default function createChecker(callback, ...initialValues) {
  let prevValues = initialValues;
  return (...values) => {
    if (!hasDiff(prevValues, values)) return;

    callback(prevValues);
    prevValues = values;
  };
}
