<script>
  import { VerticalStep, VerticalStepper } from 'wind-svelte';
  import valuationStore from './valuationStore';
  import configStore from './configStore';
  import VehicleStep from './VehicleStep.svelte';
  import ContactStep from './ContactStep.svelte';
  import ValuationStep from './ValuationStep.svelte';
  import AgenciesStep from './AgenciesStep.svelte';
  import PhoneVerificationStep from './PhoneVerificationStep.svelte';

  let showAgenciesStep = false;

  $: {
    const agencies = $valuationStore.agenciesList || $valuationStore.requiredCompanies;
    showAgenciesStep =  agencies.length > 1;
    if (!showAgenciesStep && $valuationStore.step === 4) {
      $valuationStore.step = 3;
    }
  }
</script>

<div class="px-2 py-4">
  <VerticalStepper bind:value={$valuationStore.step}>
    <VerticalStep label={$configStore.vehicleTitle}>
      <VehicleStep />
    </VerticalStep>
    <VerticalStep label={$configStore.contactTitle}>
      <ContactStep />
    </VerticalStep>
    {#if showAgenciesStep}
      <VerticalStep label={$configStore.agencyTitle}>
        <AgenciesStep />
      </VerticalStep>
    {/if}
    <VerticalStep label={$configStore.phoneVerificationTitle}>
      <PhoneVerificationStep />
    </VerticalStep>
    <VerticalStep label={$configStore.valuationTitle}>
      <ValuationStep />
    </VerticalStep>
  </VerticalStepper>
</div>
