import { writable } from 'svelte/store';

const financingConfig = writable({
  // config
  businessUnit: null,
  channel: null,
  vehicle: {},
  vehiclePrice: 0,
  fullWidth: false,
  stop: false,
  noCard: false,

  // financing step config
  interestRate: 0,
  minVehiclePrice: 0,
  maxVehiclePrice: 0,
  minDownPayment: 0,
  maxDownPayment: 0,
  financingTitle: '',
  financingDescription: '',
  financingTermsCopy: '',
  financingDisclaimer: '',
  terms: [],
  termsCols: 0,
  termsSmCols: 0,
  termsMdCols: 0,
  termsLgCols: 0,
  termsXlCols: 0,
  termsXxlCols: 0,

  // contact step config
  lastName: false,
  contactTitle: '',
  contactDesription: '',
  contactMdCols: 0,
  contactLgCols: 0,
  contactXlCols: 0,
  contactXxlCols: 0,

  // finish step config
  finishTitle: '',
  finishDescription: '',
});

export default financingConfig;
