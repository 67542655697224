<script>
  import { setContext } from 'svelte';
  import { Theme } from 'wind-svelte';
  import getStyleColors from '../utils/getStyleColors';
  import fetchConfig from '../utils/fetchConfig';
  import financingConfig from './financingConfig';
  import financingState from './financingState';
  import Financing from './Financing.svelte';

  // static props
  export let stylesHref;
  export let webComponentContainer;
  export let dispatchEvent;

  // config props
  export let style = '';
  export let fullWidth;
  export let config;
  export let businessUnit;
  export let channel;
  export let vehicle; // this field is not configurable from the editor
  export let vehiclePrice; // this is not (and must be not) configurable from the editor
  export let stop;
  export let noCard;
  
  // financing props
  export let interestRate;
  export let minVehiclePrice;
  export let maxVehiclePrice;
  export let minDownPayment;
  export let maxDownPayment;
  export let financingTitle;
  export let financingDescription;
  export let financingTermsCopy;
  export let financingDisclaimer;
  export let terms;
  export let termsCols;
  export let termsSmCols;
  export let termsMdCols;
  export let termsLgCols;
  export let termsXlCols;
  export let termsXxlCols;
  
  // contact props
  export let extraFields;
  export let lastName;
  export let contactTitle;
  export let contactDescription;
  export let contactMdCols;
  export let contactLgCols;
  export let contactXlCols;
  export let contactXxlCols;

  // finish props
  export let finishTitle;
  export let finishDescription;

  // local state
  let loadedStyles;
  let loadedConfig;
  let editorConfig = {};

  // set dispatchEvent as context
  setContext('dispatchEvent', dispatchEvent);

  // this is requiered to preserve the value when the attribute change
  // prevents a reactivity issue 
  function getExtraFieldValue(name) {
    const extraField = $financingState.extraFields.find(item => item.name === name);
    return extraField?.value ?? null;
  }

  // config props
  $: $financingConfig.businessUnit = businessUnit || editorConfig.businessUnit;
  $: $financingConfig.channel = channel || editorConfig.channel;
  $: $financingConfig.vehicle = vehicle;
  $: $financingConfig.vehiclePrice = vehiclePrice || editorConfig.vehiclePrice;
  $: $financingConfig.fullWidth = fullWidth || editorConfig.fullWidth;
  $: $financingConfig.stop = stop;
  $: $financingConfig.noCard = noCard;

  // financing step props
  $: $financingConfig.interestRate = interestRate || editorConfig.interestRate || 21;
  $: $financingConfig.minVehiclePrice = minVehiclePrice || editorConfig.minVehiclePrice || 100000;
  $: $financingConfig.maxVehiclePrice = maxVehiclePrice || editorConfig.maxVehiclePrice || 1000000;
  $: $financingConfig.minDownPayment = minDownPayment || editorConfig.minDownPayment || 15;
  $: $financingConfig.maxDownPayment = maxDownPayment || editorConfig.maxDownPayment || 80;
  $: $financingConfig.financingTitle = financingTitle || editorConfig.financingTitle || 'Financiamiento';
  $: $financingConfig.financingDescription = financingDescription || editorConfig.financingDescription || 'Personaliza tu financiamiento, indica el enganche deseado y elige el plan que mas se adapte a tus necesidades';
  $: $financingConfig.financingTermsCopy = financingTermsCopy || editorConfig.financingTermsCopy || 'Selecciona el mejor plan para ti';
  $: $financingConfig.financingDisclaimer = financingDisclaimer || editorConfig.financingDisclaimer || 'La información mostrada es calculada solo para fines **informativos** y no vinculantes, y por lo anterior sujeta a cambios y a aprovación\nTasa de interes del **{interestRate}**% anual';
  $: $financingConfig.terms = terms || editorConfig.terms || [12, 24, 36, 48];
  $: $financingConfig.termsCols = termsCols || editorConfig.termsCols || 2;
  $: $financingConfig.termsSmCols = termsSmCols || editorConfig.termsSmCols || 2;
  $: $financingConfig.termsMdCols = termsMdCols || editorConfig.termsMdCols || 2;
  $: $financingConfig.termsLgCols = termsLgCols || editorConfig.termsLgCols || 2;
  $: $financingConfig.termsXlCols = termsXlCols || editorConfig.termsXlCols || 2;
  $: $financingConfig.termsXxlCols = termsXxlCols || editorConfig.termsXxlCols || 2;

  // contact step props
  $: $financingConfig.lastName = lastName || editorConfig.lastName;
  $: $financingConfig.contactTitle = contactTitle || editorConfig.contactTitle || 'Contacto';
  $: $financingConfig.contactDescription = contactDescription || editorConfig.contactDescription || 'Ahora uno de nuestros valuadores profesionales te ayudará a agilizar el proceso';
  $: $financingConfig.contactMdCols = contactMdCols || editorConfig.contactMdCols || 1;
  $: $financingConfig.contactLgCols = contactLgCols || editorConfig.contactLgCols || 1;
  $: $financingConfig.contactXlCols = contactXlCols || editorConfig.contactXlCols || 1;
  $: $financingConfig.contactXxlCols = contactXxlCols || editorConfig.contactXxlCols || 1;

  // finish step props
  $: $financingConfig.finishTitle = finishTitle || editorConfig.finishTitle || 'Respuesta';
  $: $financingConfig.finishDescription = finishDescription || editorConfig.finishDescription || '**¡Felicidades!**\nSu financiamiento está por comenzar, nos pondremos en contacto con usted para formalizarlo y pronto podrá disfrutar su nuevo auto';

  // initial state
  $: $financingState.vehiclePrice = vehiclePrice || editorConfig.minVehiclePrice || minVehiclePrice || 100000;
  $: $financingState.downPayment = minDownPayment || editorConfig.minDownPayment || 15;
  $: $financingState.extraFields = (extraFields || editorConfig.extraFields || []).map(field => ({
    ...field,
    value: getExtraFieldValue(field.name),
  }));

  // make styles
  $: styles = getStyleColors(webComponentContainer, style) // style is added only for reactivity
  $: primary = styles.primary || editorConfig.primaryColor || 'rgb(54,182,255)';
  $: primaryContrast = styles.primaryContrast || editorConfig.primaryContrastColor;
  $: colors = {
    primary,
    // if has no primaryContrast and primary is default, force to white
    primaryContrast: primaryContrast || (primary === 'rgb(54,182,255)' ? '#ffffff' : null),
    error: styles.error || editorConfig.errorColor || 'rgb(225,28,28)',
  };

  // remote config
  async function getConfig() {
    const response = await fetchConfig(config);
    if (response) {
      editorConfig = response.data;
    }
    loadedConfig = true;
  }
  $: getConfig(config);
</script>

{#if stylesHref}
  <link rel="stylesheet" href={stylesHref} on:load={() => loadedStyles = true} />
{/if}

{#if loadedStyles && loadedConfig}
  <Theme {colors}>
    <Financing />
  </Theme>
{/if}
