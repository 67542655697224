<script>
  import AmountSlider from '../components/AmountSlider.svelte';
  import financingConfig from './financingConfig';
  import financingState from './financingState';

  let selection = 0;
  let min = 0;
  let max = 1;

  $: {
    min = ($financingConfig.minDownPayment / 100) * $financingState.vehiclePrice;
    max = ($financingConfig.maxDownPayment / 100) * $financingState.vehiclePrice;

    min = Math.round(min / 1000) * 1000;
    max = Math.round(max / 1000) * 1000;

    if (!selection) {
      selection = min;
    }

    if ($financingState.downPayment < min) {
      selection = $financingState.downPayment = min;
    } else if ($financingState.downPayment > max) {
      selection = $financingState.downPayment = max;
    }
  }
</script>

<AmountSlider
  {min}
  {max}
  step={1000}
  label="Enganche"
  bind:value={$financingState.downPayment}
  bind:selection={selection}
/>
