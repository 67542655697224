<script>
  import { Button } from 'wind-svelte';
  import valuationStore from './valuationStore';
  import configStore from './configStore';
  import AgenciesSelector from './AgenciesSelector.svelte';
  import ValuationError from './ValuationError.svelte';
  import ZipCodeFilter from './ZipCodeFilter.svelte';
  import NextStepButton from './NextStepButton.svelte';
  import AgenciesMap from './AgenciesMap.svelte';

  // reactivity
  $: agencyValid = Boolean($valuationStore.agency);
</script>

<div class="font-sans text-sm antialiased text-gray-500 leading-normal mb-2">
  {$configStore.agencyText}
</div>

<div class="mb-2">
  <ZipCodeFilter />
</div>

{#if $configStore.googleMapsKey}
  <!-- TODO: make this component as async -->
  <AgenciesMap />
{/if}

<AgenciesSelector />

<ValuationError />

<div class="flex justify-end pt-4">
  <Button
    text
    color="primary"
    on:click={() => $valuationStore.step--}
  >
    Anterior
  </Button>
  <NextStepButton disabled={!agencyValid} />
  {#if IS_DEV}
    <Button on:click={() => $valuationStore.step++}>SALTAR</Button>
  {/if}
</div>
