import { fitsh } from 'fitsh';

export default async function sendPhoneVerificationCode({
  phone,
  businessUnitId
}) {
  await fitsh(`${API_URL}/phoneVerificationCode/send`).post({
    phone,
    businessUnitId
  });
}
