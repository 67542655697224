<script>
  import Button from 'wind-svelte/components/Button.svelte';
  import { createEventDispatcher } from 'svelte';
  import valuationStore from './valuationStore';

  const dispatch = createEventDispatcher();

  function onClick() {
    valuationStore.reset();
    $valuationStore.hasValuated = true;
    dispatch('click');
  }
</script>

<Button color="primary" on:click={onClick}>
  Cotizar otro auto
</Button>
