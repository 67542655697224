<script>
  import { TextInput } from 'wind-svelte';
  import valuationStore from './valuationStore';
</script>

<TextInput
  fullWidth
  filled
  type="number"
  label="Busca por código postal"
  debounce={500}
  maxlength={5}
  counter={5}
  bind:value={$valuationStore.zipCodeFilter}
/>
