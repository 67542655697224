<script>
  export let name = '';
</script>

<a
  href="https://intelimotor.com"
  target="_blank"
  class="bg-transparent flex flex-col items-center no-underline"
>
  {#if name}
    <div class="text-xs leading-3 text-gray-600">
      {name}
    </div>
  {/if}
  <div class="text-2xs leading-3 text-gray-600">
    Powered by
  </div> 
  <img
    class="w-24 -mt-2 -mb-2"
    src="https://intelimotor.com/wp-content/uploads/2021/03/Inteli21.png"
    alt="intelimotor"
  >
</a>
