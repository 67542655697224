<script>
  import { fitsh } from 'fitsh';
  import { Select } from 'wind-svelte';
  import stateStore from './stateStore';
  import valuation from './valuationStore';
  import config from './configStore';
  import createChecker from '../utils/createChecker';

  // state
  let items = [];
  let loading = false;

  $: ({ brand } = $valuation);

  // reactivity
  async function fetchBrands() {
    try {
      loading = true;
      const response = await fitsh(`${API_URL}/brands`).get({
        countryCode: "MX",
      });

      items = response.data;
    } catch (error) {
      console.error(error);
      $stateStore.error =
        "No fue posible consultar las marcas, verifique su conexión o intente de nuevo mas tarde";
    }
    loading = false;
  }

  fetchBrands();

  const resetChecker = createChecker(() => {
    $valuation.model = null;
    $valuation.year = null;
    $valuation.trim = null;
  }, $valuation.brand?.id);
  $: resetChecker(brand?.id);
</script>

<Select
  {loading}
  fullWidth
  autocomplete
  clearable
  filled
  label="Marca"
  disabled={loading}
  options={items}
  identify={item => item?.id}
  getText={item => item?.name || ''}
  noResultsCopy={$config.noResultsCopy}
  bind:value={$valuation.brand}
/>
