<script>
  import { getColor, Markdown } from 'wind-svelte';
  import configStore from './configStore';
  import valuationStore from './valuationStore';
  import ValuationError from './ValuationError.svelte';
  import NewValuationButton from './NewValuationButton.svelte';
  import toCurrency from '../utils/toCurrency';

  const color = getColor('pricing');

  $: ({ pricingImage, rejected, rejectionMessage, suggestedOffer } = $valuationStore.valuation);  
  $: vehicle = `${$valuationStore.brand?.name} ${$valuationStore.model?.name}`;
</script>

<div class="font-sans text-sm antialiased text-black leading-normal mb-4">
  <Markdown
    template={$configStore.valuationTopText}
    variables={{ vehicle }}
  />
</div>

{#if rejected}
  <div class="font-sans mb-2">
    {rejectionMessage}
  </div>
{:else if pricingImage}
  <div class="mb-4">
    <img src={pricingImage} alt="price" />
  </div>

  <div class="font-sans text-sm antialiased leading-normal mb-4">
    {$configStore.valuationBottomText}
  </div>

  <div text="xs gray-400" class="italic mb-2">
    {$configStore.valuationDisclaimer}
  </div>
{:else if suggestedOffer}
  <div style="{$color.themeVars()}" text="color 4xl" class="mb-4">
    {toCurrency(suggestedOffer)}
    <span text="gray-400 xl">*</span>
  </div>

  <div class="font-sans text-sm antialiased leading-normal mb-4">
    {$configStore.valuationBottomText}
  </div>

  <div text="xs gray-400" class="italic mb-2">
    {$configStore.valuationDisclaimer}
  </div>
{:else}
  <div class="font-sans text-sm antialiased text-black leading-normal">
    {$configStore.noValuationText}
  </div>
{/if}


<div class="pt-2">
  <ValuationError />
  <div class="flex justify-end">
    <NewValuationButton />
  </div>
</div>
