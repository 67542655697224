<script>
  import configStore from './configStore';

  $: logo = $configStore.groupLogo;
</script>

{#if logo}
  <div class="text-center">
    <img
      class="w-20 h-20 object-contain -mb-4"
      src={logo}
      alt='group logo'
    />
  </div>
{/if}
