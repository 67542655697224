import { fitsh } from 'fitsh';

export default async function fetchConfig(configId) {
  try {
    if (!configId) return null;

    const config = await fitsh(`${API_URL}/wc-config/${configId}`).get();
    if (!config) {
      console.warn(`Invalid config id "${configId}"`)
    }

    return config;
  } catch(error) {
    console.error(error);
    return null;
  }
}
