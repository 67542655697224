const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};

export default function getLocation() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      resolve({ lat: coords.latitude, lon: coords.longitude });
    }, reject, options);
  });
}
