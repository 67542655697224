<script>
  import { Button, Markdown } from 'wind-svelte';
  import financingConfig from './financingConfig';
  import financingState from './financingState';
</script>

<div>
  <Markdown template={$financingConfig.finishDescription} />
</div>

<div class="flex justify-end">
  <Button
    color="primary"
    on:click={() => $financingState.step = 0}
  >
    Regresar
  </Button>
</div>
