<script>
  import { TextInput } from 'wind-svelte';
  import valuationStore from './valuationStore';
  import configStore from './configStore';
  import Tooltip from '../components/Tooltip.svelte';

  $: input = $configStore.vehicleFields.find((field) => field.name === 'kms');
  $: label = input ? `Kilometraje${input.optional ? ' (opcional)' : ''}${$configStore.tooltips.kms ? ' ⓘ' : ''}` : '';
</script>

<Tooltip title={$configStore.tooltips.kms}>
  <TextInput
    {label}
    fullWidth
    filled
    type="number"
    bind:value={$valuationStore.kms}
  />
</Tooltip>