<script>
  import { Grid } from 'wind-svelte';
  import TextInput from 'wind-svelte/components/TextInput.svelte';
  import phoneFormatter from '../utils/phoneFormatter';
  import CustomField from '../ValuationComponent/CustomField.svelte';
  import sendCustomEvent from '../utils/sendCustomEvent';

  export let smCols;
  export let mdCols;
  export let lgCols;
  export let xlCols;
  export let xxlCols;
  export let hasLastName;

  export let name;
  export let lastName;
  export let phone;
  export let email;
  export let extraFields;

  export let nameError = null;
  export let lastNameError = null;
  export let phoneError = null;
  export let emailError = null;

  export let hasError = false;

  $: customFieldsHasError = (extraFields || []).some((field) => field.error);
  $: hasError = nameError || lastNameError || phoneError || emailError || customFieldsHasError;

  $: nameValid = !!name;
  $: phoneValid = !!phone && !phoneError;
  $: emailValid = !!email && !emailError;

  $: nameValid && sendCustomEvent('set-contact-name');
  $: phoneValid && sendCustomEvent('set-contact-phone');
  $: emailValid && sendCustomEvent('set-contact-email');
</script>

<Grid
  spacing={0.25}
  {smCols}
  {mdCols}
  {lgCols}
  {xlCols}
  {xxlCols}
>
  <div>
    <TextInput
      filled
      fullWidth
      label="{hasLastName ? 'Nombre' : 'Nombre y apellidos'}"
      rules={[
        (text) => !!text.trim() || 'Campo requerido',
      ]}
      bind:value={name}
      bind:error={nameError}
    />
  </div>

  {#if hasLastName}
    <div>
      <TextInput
        filled
        fullWidth
        label="Apellidos"
        rules={[
          (text) => !!text.trim() || 'Campo requerido',
        ]}
        bind:value={lastName}
        bind:error={lastNameError}
      />
    </div>
  {/if}

  <div>
    <TextInput
      filled
      fullWidth
      type="phone"
      label="Número de teléfono"
      maxlength={10}
      rules={[
        (text) => !!text.trim() || 'Campo requerido',
        (text) => text.length === 10 || 'Deben ser 10 digitos',
      ]}
      formatter={phoneFormatter}
      bind:value={phone}
      bind:error={phoneError}
    />
  </div>

  <div>
    <TextInput
      filled
      fullWidth
      label="Correo electrónico"
      rules={[
        (text) => !!text.trim() || 'Campo requerido',
        (text) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(text) || 'Formato invalido',
      ]}
      bind:value={email}
      bind:error={emailError}
    />
  </div>

  {#each extraFields || [] as field}
    <div>
      <CustomField
        field={field}
        bind:value={field.value}
        bind:error={field.error}
      />
    </div>
  {/each}
</Grid>
