import defineCustomComponent from './utils/defineCustomComponent';
import ValuationComponent from './ValuationComponent/ValuationComponent.svelte';
import FinancingComponent from './FinancingComponent/FinancingComponent.svelte';

const json = (defaultValue) => (raw) => (raw ? JSON.parse(raw) : null) || defaultValue;
const text = (defaultValue) => (raw) => (typeof raw === 'string' ? raw : null) || defaultValue;
const list = (defaultValue) => (raw) => {
  if (typeof raw !== 'string') return defaultValue;
  return raw.split(/[,\s]+/).filter(Boolean).map((item) => ({
    name: /([\w-_]+)\??/.exec(item)[1],
    optional: item.endsWith('?'),
  }));
};
const planeList = (defaultValue) => (raw) => {
  if (typeof raw !== 'string') return defaultValue;
  return raw.split(/[,\s]+/).filter(Boolean);
};
const boolean = (defaultValue) => (raw) => {
  if (raw === undefined) return defaultValue;
  if (raw === '' || raw === 'true') return true;
  return false;
};
const number = (defaultValue) => (raw) => {
  if (raw === undefined) return defaultValue;
  return Number(raw) || defaultValue;
}

defineCustomComponent({
  component: ValuationComponent,
  tagName: 'intelimotor-valuation',
  staticProps: {
    stylesHref: STYLES,
  },
  props: {
    'config': text(),
    'full-width': boolean(),
    'business-units': json(),
    'vehicle-fields': list(),
    'vehicle-extra-fields': json(),
    'contact-extra-fields': json(),
    'md-cols': number(),
    'lg-cols': number(),
    'xl-cols': number(),
    'xxl-cols': number(),
    'vehicle-title': text(),
    'vehicle-text': text(),
    'contact-title': text(),
    'contact-text': text(),
    'agency-title': text(),
    'agency-text': text(),
    'valuation-title': text(),
    'valuation-top-text': text(),
    'valuation-bottom-text': text(),
    'valuation-disclaimer': text(),
    'no-valuation-text': text(),
    'legal-text': text(),
    'min-year-filter': number(),
    'no-results-copy': text(),
    'agency-distance-required': boolean(),
    'last-name': boolean(),
    'primary-color': text(),
    'primary-contrast-color': text(),
    'pricing-color': text(),
    'error-color': text(),
    'group-logo': text(),
    'google-maps-key': text(),
    'phone-verification-title': text(),
    'phone-verification-text': text(),
    'confirm-vehicle-info': boolean(),
    'confirm-vehicle-info-text': text(),
    'tooltips': json(),
  },
});

defineCustomComponent({
  component: FinancingComponent,
  tagName: 'intelimotor-financing',
  staticProps: {
    stylesHref: STYLES,
  },
  props: {
    // config
    'config': text(),
    'business-unit': text(),
    'channel': text(),
    'vehicle': json({}),
    'vehicle-price': number(),
    'full-width': boolean(),
    'stop': boolean(), // impide el fetch de la agencia
    'no-card': boolean(),

    // financing
    'interest-rate': number(),
    'min-vehicle-price': number(),
    'max-vehicle-price': number(),
    'min-down-payment': number(),
    'max-down-payment': number(),
    'financing-title': text(),
    'financing-description': text(),
    'financing-terms-copy': text(),
    'financing-disclaimer': text(),
    'terms': planeList(),
    'terms-cols': number(),
    'terms-sm-cols': number(),
    'terms-md-cols': number(),
    'terms-lg-cols': number(),
    'terms-xl-cols': number(),
    'terms-xxl-cols': number(),

    // contact
    'extra-fields': json(),
    'last-name': boolean(false),
    'contact-title': text(),
    'contact-description': text(),
    'contact-md-cols': number(),
    'contact-lg-cols': number(),
    'contact-xl-cols': number(),
    'contact-xxl-cols': number(),

    // finish
    'finish-title': text(),
    'finish-description': text(),
  },
});
