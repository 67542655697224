import toCurrency from './toCurrency';

function formatValue(value) {
  if (!value) return '';

  return toCurrency(value || 0);
}

const PATTERN = /[^\d]/g;

function toValue(text, event) {
  const parsedText = text.replace(PATTERN, '');
  return Number(parsedText);
}

function toText(value, event) {
  if (!event) return formatValue(value || 0);

  const { data, target } = event;

  // if no data, is backspace and must be formated
  if (data && PATTERN.test(data)) {
    return target.value.replace(data, '');
  }

  const newValue = toValue(target.value, event);
  return formatValue(newValue);
}

const currencyFormatter = { toText, toValue };

export default currencyFormatter;
