<script>
  import { fitsh } from 'fitsh';
  import { Modal, Button, CircularProgress, Card } from 'wind-svelte';
  import configStore from './configStore';
  import stateStore from './stateStore';
  import valuationStore from './valuationStore';

  $: webComponentContainer = $configStore.webComponentContainer;

  // props
  export let authorization = null;

  // state
  let showButton = false;
  let recaptchaId = null;

  // events
  async function createAuth(recaptcha) {
    try {
      const { token } = await fitsh(`${API_URL}/auth/valuation`).post({
        recaptcha,
      });
      authorization = token;
    } catch(error) {
      console.error(error);
      $stateStore.error = 'Ocurrió un error de autenticación, verifique la configuración del componente';
      $stateStore.disableComponent = true;
    }
  }

  function executeRecaptcha() {
    window.grecaptcha.execute(recaptchaId);
  }

  function onLoadScript() {
    window.grecaptcha.ready(() => {
      const recaptchaContainer = document.createElement('div');
      webComponentContainer.appendChild(recaptchaContainer);

      recaptchaId = window.grecaptcha.render(recaptchaContainer, {
        sitekey: RECAPTCHA_KEY,
        size: 'invisible',
        callback: createAuth,
      });

      executeRecaptcha();
    });
  }

  // delayed
  setTimeout(() => {
    showButton = true;
  }, 5000);
</script>

{#if showButton}
  <Modal>
    <Card>
      <Button
        color="primary"
        on:click={executeRecaptcha}
      >
        Continuar
      </Button>
    </Card>
  </Modal>
{:else if $valuationStore.brand && $valuationStore.model && $valuationStore.year && $valuationStore.trim}
  <Modal>
    <Card>
      <CircularProgress size={3} />
    </Card>
  </Modal>
{/if}

<svelte:head>
  <script
    on:load={onLoadScript}
    src="https://www.google.com/recaptcha/api.js?hl=es-419"
  ></script>
</svelte:head>

<style>
  :global(.grecaptcha-badge) {
    visibility: hidden;
  }
</style>
