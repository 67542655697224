<script>
  import Unchecked from "@jprochazk/svelte-material-icons/CheckboxBlankOutline.svelte";
  import Checked from "@jprochazk/svelte-material-icons/CheckboxMarked.svelte";
  import { createEventDispatcher } from "svelte";

  export let checked = false;
  export let label = "";
  export let color = null;

  const dispatch = createEventDispatcher();

  function onClick(e) {
    checked = !checked;
    dispatch("click", e);
  }
</script>

<div class="cursor-pointer flex items-center" on:click={onClick}>
  <div>
    {#if checked}
      <Checked size="1.4rem" fill={color} />
    {:else}
      <Unchecked size="1.4rem" />
    {/if}
  </div>

  {#if label}
    <span m="l-2" text="size-xs">{label}</span>
  {/if}
</div>
