<script>
  import { getContext } from "svelte";
  import { Button, TextInput } from "wind-svelte";
  import parsePhoneNumber from "libphonenumber-js";
  import fetchValuation from "../utils/fetchValuation";
  import valuationStore from "./valuationStore";
  import stateStore from "./stateStore";
  import configStore from "./configStore";
  import LegalCopy from "./LegalCopy.svelte";
  import NextStepButton from "./NextStepButton.svelte";
  import ValuationError from "./ValuationError.svelte";
  import phoneFormatter from "../utils/phoneFormatter";
  import sendPhoneVerificationCode from "../utils/sendPhoneVerificationCode";
  import checkPhoneVerificationCode from "../utils/checkPhoneVerificationCode";
  import createChecker from "../utils/createChecker";
  import sendCustomEvent from "../utils/sendCustomEvent";

  export let phoneVerificationCode = "";
  export let phoneVerificationCodeError = null;
  export let phoneVerificationCodeFailedChecks = [];

  // state
  let loading = false;

  const dispatchEvent = getContext("dispatchEvent");

  // reactivity
  const agenciesChecker = createChecker(async () => {
    if (
      $valuationStore.agenciesList &&
      $valuationStore.agenciesList.length > 0
    ) {
      sendPhoneVerificationCode({
        phone: $valuationStore.phone,
        businessUnitId: $valuationStore.agency
          ? $valuationStore.agency.id
          : $valuationStore.agenciesList[0].id,
      });
    }
  });

  $: agenciesChecker($valuationStore.agenciesList);

  // events
  async function createValuation() {
    try {
      loading = true;
      $valuationStore.valuation = await fetchValuation({
        ...$valuationStore,
        ...($valuationStore.agenciesList.length === 1
          ? { agency: $valuationStore.agenciesList[0] }
          : {}),
      });

      dispatchEvent("valuation-id", {
        id: $valuationStore.valuation.id,
      });

      $valuationStore.step++;
    } catch (error) {
      if (error.response?.status === 401) {
        $valuationStore.authorization = null;
      } else {
        console.error(error);
        $stateStore.error =
          "No fue posible generar la oferta, verifique su conexión o intente de nuevo mas tarde";
      }
      throw error;
    } finally {
      loading = false;
    }
  }
</script>

<div class="font-sans text-sm antialiased text-gray-500 leading-normal mb-4">
  {$configStore.phoneVerificationText.replace(
    "{phone}",
    $valuationStore.phone.length === 10
      ? parsePhoneNumber($valuationStore.phone, "MX").formatInternational()
      : $valuationStore.phone
  )}
</div>

<div>
  <TextInput
    filled
    fullWidth
    type="phone"
    label="Código de verificación"
    maxlength={6}
    rules={[
      (text) => !!text.trim() || "Campo requerido",
      (text) => text.length === 6 || "Deben ser 6 digitos",
      (text) =>
        !phoneVerificationCodeFailedChecks.includes(text) ||
        "Código incorrecto, intenta de nuevo",
    ]}
    formatter={phoneFormatter}
    bind:value={phoneVerificationCode}
    bind:error={phoneVerificationCodeError}
    on:focus={() => {
      phoneVerificationCodeError = null;
    }}
  />
</div>

<div class="font-sans text-sm antialiased leading-normal mb-4">
  ¿No es tu teléfono?
  <button
    class="text-gray-500 cursor-pointer underline bg-transparent border-none pl-0"
    on:click={() => {
      sendCustomEvent("change-phone");
      $valuationStore.step = 1;
    }}
  >
    Cambiar teléfono
  </button>
</div>

<LegalCopy />

<div class="mt-4">
  <ValuationError />
  <div class="flex justify-end">
    <Button
      text
      color="primary"
      disabled={loading}
      on:click={() => $valuationStore.step--}
    >
      Anterior
    </Button>
    <NextStepButton
      headless
      {loading}
      disabled={loading || Boolean(phoneVerificationCodeError)}
      on:click={async () => {
        loading = true;

        const checkResult = await checkPhoneVerificationCode({
          phone: $valuationStore.phone,
          code: phoneVerificationCode,
        });

        if (!checkResult) {
          loading = false;
          phoneVerificationCodeFailedChecks = [
            ...phoneVerificationCodeFailedChecks,
            phoneVerificationCode,
          ];
          sendCustomEvent("2fa-failed");
          return;
        }

        sendCustomEvent("2fa-ok");
        createValuation();
      }}
    />
  </div>
</div>
