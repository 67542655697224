<script>
  import stateStore from './stateStore';
  import valuationStore from './valuationStore';
  import createChecker from '../utils/createChecker';
  import ErrorAlert from '../components/ErrorAlert.svelte';

  // clear error on step changed
  const clearErrorChecker = createChecker(() => {
    $stateStore.error = '';
  }, $valuationStore.step);
  $: clearErrorChecker($valuationStore.step);
</script>

<ErrorAlert
  error={$stateStore.error}
  closable={!$stateStore.disableComponent}
  on:close={() => $stateStore.error = ''}
/>
