<script>
  import { setContext } from 'svelte';
  import { Theme } from 'wind-svelte';
  import getStyleColors from '../utils/getStyleColors';
  import fetchConfig from '../utils/fetchConfig';
  import valuationStore from './valuationStore';
  import configStore from './configStore';
  import Valuation from './Valuation.svelte';

  // static props
  export let stylesHref = null;

  // theme props (deprecated, do not use and remove in the future)
  export let primaryColor = '';
  export let primaryContrastColor = '';
  export let pricingColor = '';
  export let errorColor = '';
  
  // config props
  export let style = '';
  export let config = null;
  export let businessUnits = null;
  export let vehicleTitle = null;
  export let vehicleText = null;
  export let contactTitle = null;
  export let contactText = null;
  export let agencyTitle = null;
  export let agencyText = null;
  export let googleMapsKey = null;
  export let valuationTitle = null;
  export let valuationTopText = null;
  export let valuationBottomText = null;
  export let valuationDisclaimer = null;
  export let noValuationText = null;
  export let webComponentContainer = null;
  export let vehicleFields = null;
  export let vehicleExtraFields = null;
  export let prominent = false;
  export let contactExtraFields = false;
  export let fullWidth = false;
  export let mdCols = 0;
  export let lgCols = 0;
  export let xlCols = 0;
  export let xxlCols = 0;
  export let dispatchEvent = null;
  export let legalText = null;
  export let minYearFilter = 0;
  export let noResultsCopy = null;
  export let agencyDistanceRequired = false;
  export let lastName = false;
  export let groupLogo = null;
  export let phoneVerificationTitle = null;
  export let phoneVerificationText = null;
  export let confirmVehicleInfo = false;
  export let confirmVehicleInfoText = null;
  export let tooltips = null;

  let colors = {};
  let loadedStyles = false;
  let loadedConfig = false;
  let editorConfig = {};

  setContext('dispatchEvent', dispatchEvent);

  function getVehicleValue(name) {
    const vehicleField = $valuationStore.vehicleExtraFields.find((field) => field.name === name);
    return vehicleField ? vehicleField.value : null;
  }

  function getContactValue(name) {
    const contactField = $valuationStore.contactExtraFields.find((field) => field.name === name);
    return contactField ? contactField.value : null;
  }

  // save props
  $: $valuationStore.requiredCompanies = businessUnits || editorConfig.businessUnits || []; // TODO: move this to configStore
  $: $configStore.vehicleTitle = vehicleTitle || editorConfig.vehicleTitle || 'Identifica tu vehículo';
  $: $configStore.vehicleText = vehicleText || editorConfig.vehicleText || 'Usaremos estos datos para consultar el mercado y así darte la mejor oferta por tu vehículo.';
  $: $configStore.contactTitle = contactTitle || editorConfig.contactTitle || 'Datos de contacto';
  $: $configStore.contactText = contactText || editorConfig.contactText || 'Después de recibir tu oferta, uno de nuestros valuadores profesionales te contactará para ayudarte a agilizar el proceso.';
  $: $configStore.agencyTitle = agencyTitle || editorConfig.agencyTitle || 'Elige un centro de valuación';
  $: $configStore.agencyText = agencyText || editorConfig.agencyText || 'Una vez recibida tu oferta deberás acudir a este lugar para concretar la venta de tu vehículo.';
  $: $configStore.valuationTitle = valuationTitle || editorConfig.valuationTitle || 'Ver oferta';
  $: $configStore.valuationTopText = valuationTopText || editorConfig.valuationTopText || '**¡Listo!** Compramos tu {vehicle} al mejor precio.';
  $: $configStore.valuationBottomText = valuationBottomText || editorConfig.valuationBottomText || '¿Qué sigue? Uno de nuestros valuadores profesionales te contactará pronto para dar seguimiento a tu oferta.';
  $: $configStore.valuationDisclaimer = valuationDisclaimer || editorConfig.valuationDisclaimer || '* Precio sujeto a la inspección del vehículo.';
  $: $configStore.noValuationText = noValuationText || editorConfig.noValuationText || '¿Qué sigue? Uno de nuestros valuadores profesionales te contactará pronto para dar seguimiento a tu oferta.';
  $: $configStore.webComponentContainer = webComponentContainer || editorConfig.webComponentContainer;
  $: $configStore.vehicleFields = vehicleFields || editorConfig.vehicleFields || [];
  $: $configStore.vehicleExtraFields = vehicleExtraFields || editorConfig.vehicleExtraFields || [];
  $: $configStore.prominent = prominent || editorConfig.prominent;
  $: $configStore.googleMapsKey = googleMapsKey || editorConfig.googleMapsKey;
  $: $configStore.contactExtraFields = contactExtraFields || editorConfig.contactExtraFields;
  $: $configStore.fullWidth = fullWidth || editorConfig.fullWidth;
  $: $configStore.mdCols = mdCols || editorConfig.mdCols;
  $: $configStore.lgCols = lgCols || editorConfig.lgCols;
  $: $configStore.xlCols = xlCols || editorConfig.xlCols;
  $: $configStore.xxlCols = xxlCols || editorConfig.xxlCols;
  $: $configStore.legalText = legalText || editorConfig.legalText|| 'Al continuar aceptas los términos, condiciones y el aviso de privacidad.';
  $: $configStore.minYearFilter = minYearFilter || editorConfig.minYearFilter;
  $: $configStore.noResultsCopy = noResultsCopy || editorConfig.noResultsCopy || 'Sin resultados';
  $: $configStore.agencyDistanceRequired = agencyDistanceRequired || editorConfig.agencyDistanceRequired;
  $: $configStore.lastName = lastName || editorConfig.lastName;
  $: $configStore.groupLogo = groupLogo || editorConfig.groupLogo;
  $: $configStore.phoneVerificationTitle = phoneVerificationTitle || editorConfig.phoneVerificationTitle || 'Verifica tu teléfono';
  $: $configStore.phoneVerificationText = phoneVerificationText || editorConfig.phoneVerificationText || 'Ingrésa el código que te enviamos por SMS y WhatsApp a {phone}.';
  $: $configStore.confirmVehicleInfo = confirmVehicleInfo || editorConfig.confirmVehicleInfo || false;
  $: $configStore.confirmVehicleInfoText = confirmVehicleInfoText || editorConfig.confirmVehicleInfoText || 'Confirmo que los datos proporcionados de mi vehículo son correctos.';
  $: $configStore.tooltips = tooltips || editorConfig.tooltips || {};

  $: $valuationStore.vehicleExtraFields = (vehicleExtraFields || editorConfig.vehicleExtraFields || []).map((field) => ({
    ...field,
    value: getVehicleValue(field.name),
  }));
  $: $valuationStore.contactExtraFields = (contactExtraFields || editorConfig.contactExtraFields || []).map((field) => ({
    ...field,
    value: getContactValue(field.name),
  }));

  $: {
    const styleColors = getStyleColors(webComponentContainer, style); // style is added only for reactivity

    colors = {
      primary: primaryColor || styleColors.primary || editorConfig.primaryColor || 'rgb(54,182,255)',
      primaryContrast: primaryContrastColor || styleColors.primaryContrast || editorConfig.primaryContrastColor,
      pricing: pricingColor || styleColors.pricing || editorConfig.pricingColor || 'rgb(231,181,0)',
      error: errorColor || styleColors.error || editorConfig.errorColor || 'rgb(225,28,28)',
    };

    if (colors.primary === 'rgb(54,182,255)' && !colors.primaryContrast) {
      colors.primaryContrast = '#ffffff';
    }
  };

  async function getConfig() {
    if (config) {
      const response = await fetchConfig(config); 
      if (response) {
        editorConfig = response.data;
      }
    }
    loadedConfig = true;
  }
  $: getConfig(config);
</script>

{#if stylesHref}
  <link rel="stylesheet" href={stylesHref} on:load={() => loadedStyles = true} />
{/if}

{#if loadedStyles && loadedConfig}
  <Theme {colors}>
    <Valuation />
  </Theme>
{/if}
