function formatValue(value) {
  return value?.toString() || '';
}

const PATTERN = /[^\d]/g;

function toValue(text, event) {
  const parsedText = text.replace(PATTERN, '');
  return parsedText;
}

function toText(value, event) {
  if (!event) return formatValue(value || '');

  const { data, target } = event;

  // if there is no data, it's backspace and value must be formated
  if (data && PATTERN.test(data)) {
    return target.value.replace(data, '');
  }

  const newValue = toValue(target.value, event);
  return formatValue(newValue);
}

const phoneFormatter = { toText, toValue };

export default phoneFormatter;
