function parseColor(color) {
  if (!color) return '';

  if (/\s*,\s*\d+\s*,\s*\d+\s*/.test(color)) {
    return `rgb(${color})`;
  }
  return color;
}

export default function getStyleColors(element) {
  const style = getComputedStyle(element);

  const primary = style.getPropertyValue('--primary');
  const primaryContrast = style.getPropertyValue('--primary-contrast');
  const pricing = style.getPropertyValue('--pricing');
  const error = style.getPropertyValue('--error');

  return {
    primary: parseColor(primary),
    primaryContrast: parseColor(primaryContrast),
    pricing: parseColor(pricing),
    error: parseColor(error),
  };
}
