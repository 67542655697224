<script>
  import { fitsh } from 'fitsh';
  import { Select } from 'wind-svelte';
  import stateStore from './stateStore';
  import valuation from './valuationStore';
  import config from './configStore';
  import createChecker from '../utils/createChecker';

  let items = [];
  let loading = false;

  $: ({ model, brand, authorization } = $valuation);

  const fetchChecker = createChecker(async () => {
    if (!brand) {
      items = [];
      return;
    }
    if (!authorization) {
      loading = true;
      return;
    };
    if (model && items.length) return;

    loading = true;
    try {
      const response = await fitsh(
        `${API_URL}/brands/${brand.id}/models`
      ).get({
        authorization,
      });
      
      items = response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        $valuation.authorization = null;
      } else {
        console.error(error);
        $stateStore.error = 'No fue posible consultar las modelos, verifique su conexión o intente de nuevo mas tarde';
      }
    } finally {
      loading = false;
    }
  });
  $: fetchChecker(brand?.id, authorization);

  const resetChecker = createChecker(() => {
    $valuation.year = null;
    $valuation.trim = null;
  }, $valuation.model?.id);
  $: resetChecker(model?.id);
</script>

<Select
  {loading}
  fullWidth
  autocomplete
  clearable
  filled
  label="Modelo"
  disabled={loading}
  options={items}
  identify={item => item?.id}
  getText={item => item?.name || ''}
  noResultsCopy={$config.noResultsCopy}
  bind:value={$valuation.model}
/>
