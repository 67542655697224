<script>
  import { getColor } from 'wind-svelte';

  export let value = 0;
  export let steps = [];

  $: parsedSteps = steps.map((title, index) => ({
    title,
    isFirst: index === 0,
    isLast: index === steps.length - 1,
    selected: index <= value,
  }));

  $: _color = getColor('primary');
</script>

<div style="{$_color.themeVars('color')} {$_color.contrast.themeVars('color2')}">
  <div class="flex items-center gap-2">
    {#each parsedSteps as step, index}
      {#if step.isFirst}
        <div style="flex: 0.5;" />
      {/if}
      {#if index > 0}
        <div
          class="flex-1 h-0"
          border="0 b solid {step.selected ? 'color' : 'gray-400'}"
        />
      {/if}
      <div class="
        inline-block
        rounded-full
        bg-gray-400
        text-white
        text-center
        w-8 h-8
        leading-8
        {step.selected ? 'bg-color text-color2' : ''}
      ">
        {index + 1}
      </div>
      {#if step.isLast}
        <div style="flex: 0.5;" />
      {/if}
    {/each}
  </div>
  <div class="flex">
    {#each parsedSteps as step}
      <div class="flex-1 text-center">
        {step.title}
      </div>
    {/each}
  </div>
</div>
