import { fitsh } from 'fitsh';
import toLetters from '../utils/toLetters';

export default async function fetchAgencies({ businessUnits }) {
  const ids = businessUnits.map(item => item.businessUnit);

  const agencies = await fitsh(`${API_URL}/companies`).post({
    company: ids,
  });

  return agencies.map((agency, idx) => ({
    ...agency,
    channel: businessUnits.find(item => item.businessUnit === agency.id).channel,
    mapLabel: toLetters(idx + 1),
  }));
}
