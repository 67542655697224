import { writable } from "svelte/store";
import { getStoredContact, setStoredContact } from "../utils/storedContact";

const storedContact = getStoredContact();

const initialValue = {
  // others
  hasValuated: false,
  authorization: AUTHORIZATION || localStorage.intelimotorAuth || null,
  requiredCompanies: [],
  step: 0,

  // vehicle step
  brand: null,
  model: null,
  trim: null,
  year: null,
  kms: null,
  vin: null,
  licensePlate: null,
  vehicleExtraFields: [],
  confirmedVehicleInfo: false,

  // contact step
  name: storedContact.name,
  lastName: storedContact.lastName,
  phone: storedContact.phone,
  email: storedContact.email,
  contactExtraFields: storedContact.contactExtraFields,

  // agency step
  zipCodeFilter: '',
  agenciesList: null,
  agency: null,

  // valuation step
  valuation: null,
};

const store = writable(initialValue);

store.subscribe(
  (store) => (localStorage.intelimotorAuth = store.authorization || "")
);
store.subscribe((store) =>
  setStoredContact({
    name: store.name,
    lastName: store.lastName,
    phone: store.phone,
    email: store.email,
    contactExtraFields: store.contactExtraFields,
  })
);

const valuationStore = {
  ...store,
  reset: () => {
    store.update((oldValues) => ({
      ...oldValues,
      authorization: null,
      step: 0,
      brand: null,
      model: null,
      trim: null,
      year: null,
      kms: null,
      vin: null,
      licensePlate: null,
      vehicleExtraFields: oldValues.vehicleExtraFields.map(
        (vehicleExtraField) => ({ ...vehicleExtraField, value: null })
      ),
      agency: null,
    }));
  },
};

export default valuationStore;
