<script>
  import { createEventDispatcher, getContext } from 'svelte';
  import { Button } from 'wind-svelte';
  import valuationStore from './valuationStore';

  export let loading = false;
  export let disabled = false;
  export let headless = false;

  const dispatch = createEventDispatcher();
  const dispatchEvent = getContext('dispatchEvent');

  function getStepName(step) {
    const agencies = $valuationStore.agenciesList || $valuationStore.requiredCompanies;
    const agenciesStepEnabled = agencies.length > 1;

    if (step === 0) return 'vehicle';
    if (step === 1) return 'contact';
    if (agenciesStepEnabled) {
      if (step === 2) return 'agency';
      if (step === 3) return 'phone-verification';
      if (step === 4) return 'valuation';
    } else {
      if (step === 2) return 'phone-verification';
      if (step === 3) return 'valuation';
    }
  }

  function next() {
    const nextStepName = getStepName($valuationStore.step + 1);
    dispatchEvent('next-step', {
      to: nextStepName,
      state: $valuationStore,
    });
    $valuationStore.step++;
  }

  function onClick() {
    if (headless) {
      dispatch('click', next);
    } else {
      next();
    }
  }
</script>

<Button
  {loading}
  {disabled}
  color="primary"
  on:click={onClick}
>
  Siguiente
</Button>
