<script>
  import { TextInput } from 'wind-svelte';
  import valuationStore from './valuationStore';
  import configStore from './configStore';

  export let required = false;

  const rules = [
    (value) => required
      ? value.length === 17 || 'Deben ser 17 caracteres'
      : value.length === 0 || value.length === 17 || 'Deben ser 17 caracteres o vacío',
  ];

  $: input = $configStore.vehicleFields.find((field) => field.name === 'vin');
  $: label = input ? `VIN${input.optional ? ' (opcional)' : ''}` : '';
</script>

<TextInput
  {label}
  fullWidth
  filled
  counter={17}
  maxlength={17}
  rules={rules}
  bind:value={$valuationStore.vin}
/>
