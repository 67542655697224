import camelizeAttribute from './camelizeAttribute';

export default function defineCustomComponent({
  component,
  tagName,
  props,
  staticProps = {},
}) {
  const definedComponent = customElements.get(tagName);
  if (definedComponent) {
    return definedComponent;
  }

  let shadow
  class CustomComponent extends HTMLElement {
    static get observedAttributes() {
      return [...Object.keys(props), 'style'];
    }

    constructor(...props) {
      super(...props);
      shadow = this.attachShadow({ mode: 'closed' });
    }
    
    attributeChangedCallback(name, oldValue, newValue) {
      if (!this.component) return;

      const propName = camelizeAttribute(name);
      const propValue = name in props ? props[name](newValue) : newValue;

      this.component.$$set({ [propName]: propValue });
    }

    connectedCallback() {
      if (this.component) return;
      this.component = new component({
        target: shadow,
        props: {
          ...this.getAttributes(),
          ...staticProps,
          webComponentContainer: this,
          dispatchEvent: (name, detail) => {
            const event = new CustomEvent(name, { detail });
            this.dispatchEvent(event);
          },
        }
      });
    }
  
    getAttributes() {
      const attributes = Object.entries(props).reduce((accumulator, [attributeName, attributeParser]) => {
        const attribute = this.attributes.getNamedItem(attributeName);

        const propName = camelizeAttribute(attributeName);
        const propValue = attributeParser(attribute ? attribute.value : undefined);

        return {
          ...accumulator,
          [propName]: propValue,
        };
      }, {});

      const style = this.attributes.getNamedItem('style');

      return {
        ...attributes,
        style: style ? style.value : '',
      };
    }
  }

  customElements.define(tagName, CustomComponent);

  return CustomComponent;
}
