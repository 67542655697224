<script>
  import { OptionsSelector } from 'wind-svelte';
  import valuationStore from './valuationStore';
  import toNumber from '../utils/toNumber';
  import geoDistance from '../utils/geoDistance';
  import getLocation from '../utils/getLocation';
  import fetchZipCodeLocation from '../utils/fetchZipCodeLocation';
  import configStore from './configStore';

  let geoLocation = null;
  let zipCodeLocation = null;
  let options = [];

  getLocation().then((value) => {
    geoLocation = value;
  });

  $: zipCodeLength = $valuationStore.zipCodeFilter.toString().length;
  $: if (zipCodeLength === 5) {
    fetchZipCodeLocation({
      zipCode: $valuationStore.zipCodeFilter,
      authorization: $valuationStore.authorization,
    }).then((value) => {
      zipCodeLocation = value;
    });
  } else {
    zipCodeLocation = null;
  }

  $: location = zipCodeLocation || geoLocation;
  $: if (location) {
    options = ($valuationStore.agenciesList || []).sort((a, b) => {
      const aDistance = geoDistance(location, a.address);
      const bDistance = geoDistance(location, b.address);

      return aDistance - bDistance;
    });
  } else {
    options = $valuationStore.agenciesList || [];
  }
  $: showAgencies = $configStore.agencyDistanceRequired ? !!location : true;
</script>

{#if showAgencies}
  <div class="agencies-container">
    <OptionsSelector
      unselectable
      options={options}
      pagination={5}
      bind:value={$valuationStore.agency}
      let:option={agency}
      let:index
    >
      <div class="agency-item">
        {#if $configStore.googleMapsKey}
          <b>{`(${agency.mapLabel}) `}</b>
        {/if}
        {agency.name}
        {#if location}
          {agency.name ? '- ' : 'Ubicado a '}{toNumber(geoDistance(location, agency.address))} kms
        {/if}
        <div class="font-sans text-sm antialiased text-gray-500">
          {[
            agency.address.streetAddress,
            agency.address.neighborhood,
            agency.address.city,
            agency.address.state,
          ]
            .filter((item) => item)
            .join(', ')}
        </div>
      </div>
    </OptionsSelector>
  </div>
{/if}
