import { fitsh } from 'fitsh';

export default async function checkPhoneVerificationCode({ phone, code }) {
  const { success } = await fitsh(`${API_URL}/phoneVerificationCode/check`).post({
    phone,
    code,
  });

  return success;
}
