/**
 * @typedef { Object } Contact
 * @property { string? } name
 * @property { string? } lastName
 * @property { string? } phone
 * @property { string? } email
 * @property { Array<{ type: string, name: string, value: string, required: boolean }>? } contactExtraFields
 */

/**
 * @returns { Contact }
 */
export function getStoredContact() {
  const contact = JSON.parse(localStorage.intelimotorContact || '{}');

  if (!contact.name) contact.name = '';
  if (!contact.lastName) contact.lastName = '';
  if (!contact.phone) contact.phone = '';
  if (!contact.email) contact.email = '';

  if (!contact.contactExtraFields) contact.contactExtraFields = [];

  return contact;
}

export function setStoredContact(contact) {
  localStorage.intelimotorContact = JSON.stringify(contact);
}
